// Example: SystemNavbar.js
import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import useIsUser from '../hooks/useIsUser';

const SystemNavbar = ({ system, systemInfo, handleNavClick }) => {
    const { isUser } = useIsUser();
    const location = useLocation();

    return (
        <>
            <Nav.Link
                as={Link}
                to={`/${system}/controls`}
                state={{ systemInfo }}
                className={`nav-link ${location.pathname.includes("/controls") ? "active" : ""}`}
                onClick={handleNavClick}
            >
                Controls
            </Nav.Link>
            <Nav.Link
                as={Link}
                to={`/${system}/schedule`}
                state={{ systemInfo }}
                className={`nav-link ${location.pathname.includes("/schedule") ? "active" : ""}`}
                onClick={handleNavClick}
            >
                Schedule
            </Nav.Link>
            {/* Only show the Users link if isUser is false */}
            {!isUser && (
                <Nav.Link
                    as={Link}
                    to={`/${system}/account`}
                    state={{ systemInfo }}
                    className={`nav-link ${location.pathname.includes("/account") ? "active" : ""}`}
                    onClick={handleNavClick}
                >
                    Users
                </Nav.Link>
            )}
        </>
    );
};

export default SystemNavbar;