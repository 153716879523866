import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetUser } from "../../../shared/hooks/useGetUser";
import { Button } from "react-bootstrap";
import "../Css/fieldSelector.css";

function SystemSelector() {
  const navigate = useNavigate();
  const { user, loading, error } = useGetUser();

  const [systems, setSystems] = useState([]);

  useEffect(() => {
    let intervalId;

    const fetchSystems = () => {
      if (!loading && !error && user) {
        const usersSystems = Array.isArray(user.systems) ? user.systems : [];
        const sortedSystems = usersSystems.filter(system => system?.name).sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        if (sortedSystems.length > 0) {
          clearInterval(intervalId); // Stop polling once we find at least one system
          setSystems(sortedSystems);

          // Navigate if there's only one system
          if (sortedSystems.length === 1) {
            openSystem(sortedSystems[0]);
          }
        }
      }
    };

    // Check for systems immediately and then at intervals
    fetchSystems();
    intervalId = setInterval(fetchSystems, 5000); // Check every 5 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [user, loading, error]);

  const openSystem = (systemInfo) => {
    try {
      const systemName = systemInfo.name.replace(/[.\s]+/g, "").toLowerCase();
      navigate(`/${systemName}/controls`, { state: { systemInfo } });
    } catch (err) {
      console.error("Error navigating to system:", err);
    }
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="field-container">
      {systems?.length > 1 ? (
        systems.map((system) => (
          <Button
            key={system.id}
            className="selector-button"
            onClick={() => openSystem(system)}
          >
            {system.name}
          </Button>
        ))
      ) : (
        <div className="text-center">
          <p>Searching for systems...</p>
        </div>
      )}
    </div>
  );
}

export default SystemSelector;