// src/Features/Users/Components/UserSettingsModal.js

import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Role } from "../../../models"

//Temp, hard worded roles into drop down. not that big of a deal right now but when we do the update 
// to have many different roles will have to change. 

function UserSettingsModal({
    systemInfo,
    show,
    onHide,
    userToModify,
    selectedRole,
    setSelectedRole,
    onUpdateUserRole,
    onDeleteUser,
}) {
    const handleClose = () => {
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header data-bs-theme="dark">
                <Modal.Title>User Settings</Modal.Title>
                <Button
                    variant="close"  // or whatever style is appropriate
                    onClick={handleClose}
                    className="ms-auto close-btn"

                />
            </Modal.Header>
            <Modal.Body>
                {userToModify && (
                    <>
                        <p>
                            <strong>Email: </strong> {userToModify.email}
                        </p>
                        <Form>
                            <Form.Group controlId="userRoleSelect" className="d-flex align-items-center">
                                <Form.Label className="mb-0 me-2 fw-bold" data-bs-theme="dark">Select Role:</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedRole}
                                    onChange={(e) => setSelectedRole(e.target.value)}
                                    style={{ width: "75%" }}
                                    data-bs-theme="dark"
                                >
                                    {Object.values(Role).map((r) => (
                                        <option key={r} value={r}>
                                            {r}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <div>
                    {/* Button to update the user’s role */}
                    <Button variant="primary" data-bs-theme="dark" onClick={() => onUpdateUserRole(userToModify, selectedRole, systemInfo)} className="schedule-btn save-btn">
                        Save
                    </Button>
                </div>
                <div>
                    {/* Button to delete the user from the system */}
                    <Button variant="danger" onClick={onDeleteUser} className="schedule-btn delete-btn">
                        Delete User
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default UserSettingsModal;