/**
 * Custom hook to determine if the given user email belongs to 'willbrands'.
 *
 * @param {string} userEmail - The email of the user to check.
 * @returns {boolean} - Returns true if the user email includes 'willbrands', otherwise false.
 */
import { useState, useEffect } from 'react';
import { fetchUserAttributes } from 'aws-amplify/auth';

const useIsWill = () => {
    const [isWill, setIsWill] = useState(false);

    useEffect(() => {
        const fetchUserEmail = async () => {
            try {
                // fetchUserAttributes() is assumed to return an object with an 'email' property
                const { email } = await fetchUserAttributes();
                // Update state based on whether the email includes 'willbrands'
                setIsWill(email.includes('willbrands'));
            } catch (error) {
                console.error("Error fetching user attributes:", error);
            }
        };

        fetchUserEmail();
    }, []);

    return isWill;
};

export default useIsWill;