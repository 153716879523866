import React from "react";
import { Card, Button, Row } from "react-bootstrap";
import { getRGBColor } from "../Utils/ColorUtility";
import { getBackgroundColor } from "../Utils/getBackgroundColor";

const ColorSelector = ({
  zone,
  RGBValues,
  RGBCommands,
  handleColorButtonClick,
  isButtonSelected,
  selectedColors,
}) => {
  // Function to handle color selection
  const handleColorClick = (color) => {
    const hashed = color
      .split(",")
      .map((v) => `#${v}`)
      .join(",");

    const commandExists =
      isButtonSelected(zone, "color", color) ||
      isButtonSelected(zone, "color", hashed);

    if (commandExists) {
      handleColorButtonClick(zone, RGBCommands, hashed);
    } else {
      handleColorButtonClick(zone, RGBCommands, color);
    }
  };

  return (
    <Card.Body>
      <Card.Title as="h6" style={{ color: "white" }}>Color Selector</Card.Title>
      <Row style={{ justifyContent: "center" }}>
        {RGBValues.map((value, i) => {
          const rgbColor = getRGBColor(i);
          const hashed = value
            .split(",")
            .map((v) => `#${v}`)
            .join(",");
          return (
            <Button
              key={`color-button-${zone}-${i}`}
              className={`color-button-style ${isButtonSelected(zone, "color", value) ||
                isButtonSelected(zone, "color", hashed)
                ? "selected-button"
                : ""
                }`}
              style={{
                backgroundColor: rgbColor,
                color: "white",
                minWidth: "70px",
                maxWidth: "80px",
              }}
              onClick={() => handleColorClick(value)}
            />
          );
        })}
      </Row>

      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          width: "70%",
          borderRadius: "10px",
          height: "20px",
          backgroundColor: getBackgroundColor(zone, RGBValues, selectedColors),
          margin: "20px auto",
          color: "black",
        }}
        data-testid="background-color-div"
      ></div>

      {/* "No Color" Button - toggles between "#0,#0,#0" and "0,0,0" */}
      <Button
        key={`color-no-${zone}`}
        className={`no-blank ${isButtonSelected(zone, "color", "#0,#0,#0") ||
          isButtonSelected(zone, "color", "0,0,0")
          ? "selected-button"
          : ""
          }`}
        onClick={() => {
          const commandExists =
            isButtonSelected(zone, "color", "#0,#0,#0") ||
            isButtonSelected(zone, "color", "0,0,0");

          if (!commandExists) {
            handleColorButtonClick(zone, RGBCommands, "0,0,0");
          } else {
            handleColorButtonClick(zone, RGBCommands, "#0,#0,#0");
          }
        }}
      >
        No Color
      </Button>
    </Card.Body>
  );
};

export default ColorSelector;