// src/Features/Scheduler/Utils/api/deleteSchedule.js

import axios from 'axios';

const API_BASE_URL = 'https://j3pp969u1j.execute-api.us-west-2.amazonaws.com/prod';

/**
 * Deletes a schedule by its ID.
 * @param {string} iotName - The IoT device name.
 * @param {string} scheduleId - The ID of the schedule to delete.
 * @returns {Promise} - Axios delete promise.
 */
const deleteSchedule = (iotName, scheduleId) => {
    return axios.delete(`${API_BASE_URL}/delete-schedule`, {
        data: { iotName, ScheduleID: scheduleId },
    });
};

export default deleteSchedule;