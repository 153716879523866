import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import useSignOut from "shared/hooks/useSignOut";
import Layout from "Components/Layout/Components/Layout";
import SystemSelector from "Components/Selector/Components/SystemSelector";
import FieldLoader from "Components/FieldStatus/Components/FieldLoader";
import LightingControls from "Components/Controls/Components/LightingControlsContainer";
import Scheduler from "Components/Scheduler/Components/Scheduler";
import Account from "Components/Users/Screens/Layout";
import UserManLayout from "Components/Settings/WiLLAccess/Components/Layout";

function AppContent() {
    const signOut = useSignOut();

    return (
        <Routes>
            <Route path="/" element={<Layout signOut={signOut} />}>
                <Route index element={<SystemSelector />} />
                <Route path=":field" element={<FieldLoader />}>
                    <Route index element={<LightingControls />} />
                    <Route path="controls" element={<LightingControls />} />
                    <Route path="schedule" element={<Scheduler />} />
                    <Route path="account" element={<Account />} />
                </Route>
                <Route path="Manage" element={<UserManLayout />} />
                <Route path="/radiofield" element={<Navigate to="/" />} />
            </Route>
        </Routes>
    );
}

export default AppContent;