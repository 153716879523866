import { DataStore } from "@aws-amplify/datastore";
import { User, SystemUser } from "../../../../models";
import HandleSignUp from "../../../../shared/utils/HandleSignUp";
import { System } from "../../../../models";

//TODO: Create delete systemUser/system function.

// Multiple system logic for WiLL Access .

export const inviteNewUserToMultipleSystems = async (email, systems) => {
    try {
        const userExists = await DataStore.query(User, (u) => u.email.eq(email));
        if (!userExists.length) {
            // Use the handleSignUp function
            await HandleSignUp(email);
        }
        const user = await DataStore.query(User, (u) => u.email.eq(email));
        if (user.length) {
            const selectedSystems = systems.filter((system) => system.selected);
            await Promise.all(
                selectedSystems.map((system) =>
                    DataStore.save(
                        new SystemUser({
                            userID: user[0].id,
                            systemID: system.id,
                            role: "User", // Default role, you can change this as needed
                        })
                    )
                )
            );
        }
    } catch (error) {
        console.error("Error inviting user:", error);
    }
};

export const fetchUserSystems = async (email) => {
    try {
        // Query the user by email
        const user = await DataStore.query(User, (u) => u.email.eq(email));
        if (user.length) {
            // Query system-user relationships for the user's ID
            const systemUsers = await DataStore.query(SystemUser, (su) =>
                su.userID.eq(user[0].id)
            );

            // Extract system IDs
            const systemIDs = systemUsers.map((su) => su.systemID);

            // Query all systems
            const systems = await DataStore.query(System);

            // Filter systems to include only those matching the extracted IDs
            const userSystems = systems.filter((system) =>
                systemIDs.includes(system.id)
            );

            // Return the names of the matched systems
            return userSystems;
        }

        return [];
    } catch (error) {
        console.error("Error fetching user systems:", error);
        return [];
    }
};

export const fetchAllSysytems = async () => {
    try {
        const systems = await DataStore.query(System);
        return systems

    } catch (error) {
        console.error("Error fetching system user:", error);
    }
}

export const fetchAllUsers = async () => {
    try {
        const users = await DataStore.query(User);
        return users;
    } catch (error) {
        console.error("Error fetching all users:", error);
    }
}


export const updateUserAccess = async (username, systems) => {
    try {
        const user = await DataStore.query(User, (u) => u.email.eq(username));
        if (user.length) {
            // Remove existing SystemUser entries for the user
            const existingSystemUsers = await DataStore.query(SystemUser, (su) =>
                su.userID.eq(user[0].id)
            );
            await Promise.all(
                existingSystemUsers.map((su) => DataStore.delete(su))
            );

            // Add new SystemUser entries for the user
            await Promise.all(
                systems.map((systemID) =>
                    DataStore.save(
                        new SystemUser({
                            userID: user[0].id,
                            systemID: systemID,
                            role: "User", // Default role, you can change this as needed
                        })
                    )
                )
            );
        }
    } catch (error) {
        console.error("Error updating user access:", error);
    }
};

// Do we need this with new backend?
export const updateUserPermissions = async (selectedUser, systems) => {
    try {
        console.log(selectedUser)
        console.log(systems)
        // Remove existing associations
        const existingAssociations = await DataStore.query(SystemUser, (c) =>
            c.userID.eq(selectedUser.id)
        );
        await Promise.all(
            existingAssociations.map((assoc) => DataStore.delete(assoc))
        );

        for (const system of systems) {
            await DataStore.save(
                new SystemUser({ role: "Admin", systemID: system, userID: selectedUser.id })
            );
        }

        console.log("Updated user pools for:", selectedUser.email);
        alert("User's systems updated.");
    } catch (error) {
        console.error("Error updating user permissions:", error);
    }
};



export const updateUserFieldAssociations = async (selectedUser, userPools) => {
    try {
        // Remove existing associations
        const existingAssociations = await DataStore.query(SystemUser, (c) =>
            c.userID.eq(selectedUser.id)
        );
        await Promise.all(
            existingAssociations.map((assoc) => DataStore.delete(assoc))
        );

        // Create new associations based on selected checkboxes
        const selectedPools = userPools.filter((pool) => pool.selected);
        for (const pool of selectedPools) {
            await DataStore.save(
                new SystemUser({ systemID: pool.id, userID: selectedUser.id })
            );
        }

        console.log("Updated user pools for:", selectedUser.email);
        alert("User pools updated.");
    } catch (error) {
        console.error("Error updating user field associations:", error);
    }
};