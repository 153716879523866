// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Role = {
  "USER": "User",
  "ADMIN": "Admin"
};

const { User, System, SystemUser, Zone } = initSchema(schema);

export {
  User,
  System,
  SystemUser,
  Zone,
  Role
};