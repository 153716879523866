// src/Features/Controls/Hooks/useLightingControls.js

import { useState, useEffect } from "react";
import {
  sendDimmingMessage,
  sendColorMessage,
  sendControlMessage,
} from "../../Controls/Utils/MessageUtils"; // Utility functions for sending messages
import { useGetUser } from "../../../shared/hooks/useGetUser"; // Importing the user hook for logging
import { log } from "../../../shared/utils/Utils"; // Importing the log utility

const useLightingControls = (shadowState, systemInfo) => {
  // Use optional chaining in case systemInfo is not yet defined.
  const thingName = systemInfo?.IOTThingName || "";
  const [clickedButton, setClickedButton] = useState(null);
  const [selectedButtons, setSelectedButtons] = useState({});
  const [selectedColors, setSelectedColors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasActiveScene, setHasActiveScene] = useState({});
  const currentUser = useGetUser(); // Get the current user for logging

  useEffect(() => {
    if (!systemInfo) {
      //console.warn("systemInfo not available, waiting...");
      return;
    }
    processShadowState(shadowState);
    logShadowUpdate(shadowState, "LightingControls"); // Log shadow state updates
  }, [shadowState, systemInfo]);

  // Logging function integrated into the hook
  const logShadowUpdate = (updateMessage, projectName) => {
    if (!currentUser) {
      console.error("Error: Current user is not available for logging.");
      return;
    }
    const date = new Date();
    log(
      `shadow_update_${projectName.toLowerCase()}`,
      `User ${currentUser} published the following update message: ${JSON.stringify(
        updateMessage
      )} at ${date.toISOString()}`
    );
  };

  const processShadowState = (shadowState) => {
    const newSelectedButtons = {};
    const newSelectedColors = {};
    const newHasActiveScene = {};

    for (const key in shadowState) {
      const value = shadowState[key];
      let buttonKey;
      let zone;

      if (key.startsWith("levelWhite")) {
        zone = key.replace("levelWhite", "");
        const rawValue = value.includes("#") ? value.split("#")[1] : value;
        const parsedValue = parseInt(rawValue, 10);
        const level = isNaN(parsedValue)
          ? 0
          : Math.round((parsedValue / 255) * 100);
        buttonKey = zone ? `White-${zone}-${level}` : `dimmer-White-${level}`;
      } else if (key.startsWith("levelRgb")) {
        zone = key.replace("levelRgb", "");
        const rawValue = value.includes("#") ? value.split("#")[1] : value;
        const parsedValue = parseInt(rawValue, 10);
        const level = isNaN(parsedValue)
          ? 0
          : Math.round((parsedValue / 255) * 100);
        buttonKey = zone ? `Rgb-${zone}-${level}` : `dimmer-Rgb-${level}`;
      } else if (key.startsWith("valueRgb")) {
        zone = key.replace("valueRgb", "");
        let color;

        if (value.includes("{") && value.includes("}")) {
          // Extract the substring between "{" and "}"
          const start = value.indexOf("{") + 1;
          const end = value.indexOf("}", start);
          color = value.substring(start, end);
        } else {
          // Use the entire value if "{" and "}" are not present
          color = value;
        }

        buttonKey = zone ? `color-${zone}-${color}` : `color-${color}`;
        newSelectedColors[zone] = color;
      } else if (key.startsWith("active")) {
        const match = key.match(/Scene|Show|Pattern|Effect|Music|Schedule/);
        const type = match ? match[0] : "Unknown";
        zone = key.replace(`active${type}`, "");
        buttonKey = zone ? `${type}-${zone}-${value}` : `${type}-${value}`;

        if (key.startsWith("activeScene") && value !== "No Scene") {
          newHasActiveScene[zone] = true; // Set active scene state per zone
        }
      }

      if (buttonKey) {
        newSelectedButtons[buttonKey] = true;
      }
    }

    setHasActiveScene(newHasActiveScene);
    setSelectedButtons(newSelectedButtons);
    setSelectedColors(newSelectedColors);
    setIsLoading(false); // Data is loaded and processed
  };

  const handleDimmerButtonClick = (zone, type, command, value) => {
    // If systemInfo isn't available, retry after 1 second.
    if (!systemInfo) {
      console.warn(
        "systemInfo not available in handleDimmerButtonClick. Retrying..."
      );
      return setTimeout(
        () => handleDimmerButtonClick(zone, type, command, value),
        1000
      );
    }

    const sanitizedZone = zone.replace(/[.\s]+/g, "");

    // Determine the effective command
    const effectiveCommand = command || (value !== undefined && ``);

    // Prepare the arguments for sendDimmingMessage
    const messageArgs = [
      sanitizedZone,
      type,
      effectiveCommand,
      value,
      thingName,
      systemInfo,
    ].filter(arg => arg !== null && arg !== undefined); // Remove null or undefined args

    sendDimmingMessage(...messageArgs)
      .then(() => {
        logAction("Dimming", { zone, type, command, value }); // Log the action
      })
      .catch((e) => console.error("Failed to send dimming message", e));

    // Update clickedButton state
    const buttonIdentifier = command
      ? `dimmer-${zone}-${type}-${command}-${value}`
      : `dimmer-${zone}-${type}-${value}`;
    setClickedButton(buttonIdentifier);
  };

  const handleColorButtonClick = (zone, command = null, value) => {
    // If systemInfo isn't available, retry after 1 second.
    if (!systemInfo) {
      console.warn(
        "systemInfo not available in handleColorButtonClick. Retrying..."
      );
      return setTimeout(
        () => handleColorButtonClick(zone, command, value),
        1000
      );
    }

    // Step 1: Validate input parameters
    if (typeof zone !== "string" || zone.trim() === "") {
      console.error("Invalid zone provided:", zone);
      return;
    }

    if (typeof value !== "string" || value.trim() === "") {
      console.error("Invalid value provided:", value);
      return;
    }

    // Step 2: Sanitize the zone name
    const sanitizedZone = zone.replace(/[.\s]+/g, "");

    // Step 3: Determine if 'command' is provided
    const isCommandProvided = command !== null;

    // Step 4: Construct the messageValue based on the presence of a command
    const messageValue = isCommandProvided ? `#${value}` : `${value}`;

    const rgbValue = !isCommandProvided ? value.replace(/#/g, "") : `${value}`;

    // Step 5: Send the color message
    sendColorMessage(
      sanitizedZone,
      command, // Pass the command directly; it can be a string or null
      rgbValue,
      thingName,
      systemInfo
    )
      .then(() => {
        logAction("Color", { zone, command, value }); // Log the action
      })
      .catch((e) => console.error("Failed to send color message", e));

    // Step 7: Update the clickedButton state
    const buttonIdentifier = isCommandProvided
      ? `color-${zone}-${command}-#${value}`
      : `color-${zone}-${value}`;
    setClickedButton(buttonIdentifier);

    // Step 8: Update the selectedColors state
    setSelectedColors((prevColors) => ({
      ...prevColors,
      [zone]: rgbValue,
    }));
  };

  const handleControlButtonClick = (zone, type, value) => {
    // If systemInfo isn't available, retry after 1 second.
    if (!systemInfo) {
      console.warn(
        "systemInfo not available in handleControlButtonClick. Retrying..."
      );
      return setTimeout(
        () => handleControlButtonClick(zone, type, value),
        1000
      );
    }

    const sanitizedZone = zone ? zone.replace(/\s+/g, "") : "";

    sendControlMessage(sanitizedZone, type, value, thingName, systemInfo)
      .then(() => {
        logAction("Control", { zone, type, value }); // Log the action
      })
      .catch((e) => console.error("Failed to send control message", e));
    setClickedButton(`scene-${zone}-${type}-${value}`);
  };

  const isButtonSelected = (zone, type, value, command = null) => {
    // Return false if value is null or undefined.
    if (value == null) {
      return false;
    }

    // Use the same sanitization as in your other functions.
    const sanitizedZone = zone ? zone.replace(/[.\s]+/g, "") : "";

    // Construct the button id consistently.
    // If a command is provided, include it in the key.
    const buttonId = command
      ? `${type}-${sanitizedZone}-${command}-#${value}`
      : zone
        ? `${type}-${sanitizedZone}-${value}`
        : `${type}-${value}`;

    // Return true if either the selectedButtons object or clickedButton matches the buttonId.
    return Boolean(selectedButtons[buttonId] || clickedButton === buttonId);
  };

  // General logging function for actions
  const logAction = (actionType, details) => {
    if (!currentUser) {
      console.error("Error: Current user is not available for logging.");
      return;
    }

    const date = new Date();
    log(
      `${actionType}_action`,
      `User ${currentUser} performed ${actionType} action with details: ${JSON.stringify(
        details
      )} at ${date.toISOString()}`
    );
  };

  return {
    clickedButton,
    selectedButtons,
    selectedColors,
    isLoading,
    hasActiveScene,
    handleDimmerButtonClick,
    handleColorButtonClick,
    handleControlButtonClick,
    isButtonSelected,
  };
};

export default useLightingControls;