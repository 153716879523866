import * as React from "react";
import { useState, useContext } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { inviteUserToSystem } from "../Utils/userManUtils";
import "../Css/users.css";
import SystemInfoContext from "../../FieldStatus/Context/SystemInfoContext";
import { Role } from "../../../models";

function InviteUser() {
  const systemInfo = useContext(SystemInfoContext);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("user"); // Default role value
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      // Update the invite function to include the role if necessary.
      await inviteUserToSystem(email, role, systemInfo);
    } catch (error) {
      console.error("Error inviting user:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="card-custom-width">
      <Card.Header as="h4">Invite Users</Card.Header>
      <Card.Body>
        <Card.Text></Card.Text>
        <Form className="form" onSubmit={handleSubmit}>
          {/* Email input */}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Enter email"
              data-bs-theme="dark"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          {/* Role Dropdown */}
          <Form.Group className="mb-3" controlId="formBasicRole">
            <Form.Label>Role</Form.Label>
            <Form.Select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              data-bs-theme="dark"
            >
              {Object.values(Role).map((r) => (
                <option key={r} value={r}>
                  {r}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {/* Submit button */}
          <Button
            type="submit"
            className="my-button-style-settings"
            disabled={isLoading}
          >
            Submit
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default InviteUser;