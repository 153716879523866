// ManagePermissions.js
import * as React from "react";
import { useEffect, useState } from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import "@aws-amplify/ui-react/styles.css";
import {
  updateUserPermissions,
  fetchAllSysytems, // Ensure this is correctly spelled as fetchAllSystems if it's a typo
  fetchAllUsers,
  fetchUserSystems,
} from "../Utils/willUserUtils";
import "../Css/willAccess.css";

function ManagePermissions() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [systems, setSystems] = useState([]);
  const [error, setError] = useState(null);

  // Fetch systems and users once on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedSystems = await fetchAllSysytems(); // Check the spelling here
        setSystems(fetchedSystems);

        const fetchedUsers = await fetchAllUsers();
        setUsers(fetchedUsers.sort((a, b) => a.email.localeCompare(b.email)));
      } catch (error) {
        console.error("Error fetching systems or users:", error);
        setError("Failed to fetch systems or users.");
      }
    };

    fetchData();
  }, []);

  // Handle checkbox state changes
  const handleCheckboxChange = (systemId) => {
    setSystems((prevSystems) =>
      prevSystems.map((system) =>
        system.id === systemId
          ? { ...system, selected: !system.selected }
          : system
      )
    );
  };

  const handleSelectChange = async (event) => {
    const userEmail = event.target.value;
    const user = users.find((u) => u.email === userEmail);
    setSelectedUser(user);

    if (user) {
      setIsLoading(true); // Start loading
      try {
        const userSystems = await fetchUserSystems(user.email);

        // Ensure userSystems is an array of objects with an "id" property
        if (!Array.isArray(userSystems) || !userSystems.every((sys) => sys.id)) {
          throw new Error("fetchUserSystems should return an array of objects with 'id' properties.");
        }

        // Iterate over each system and set 'selected' based on presence in userSystems
        setSystems((prevSystems) =>
          prevSystems.map((system) => ({
            ...system,
            selected: userSystems.some((userSystem) => userSystem.id === system.id),
          }))
        );
      } catch (err) {
        console.error("Error fetching user systems:", err);
        setError(err.message || "Failed to fetch user systems.");
      } finally {
        setIsLoading(false); // End loading
      }
    } else {
      // If no user is selected, reset systems' selected state
      setSystems((prevSystems) =>
        prevSystems.map((system) => ({ ...system, selected: false }))
      );
    }
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedUser) {
      console.error("No user selected");
      setError("Please select a user before submitting.");
      return;
    }

    setIsLoading(true);
    try {
      // Gather the final list of system IDs the user is allowed to access
      const selectedSystemIds = systems
        .filter((sys) => sys.selected)
        .map((sys) => sys.id);

      // Update user permissions
      await updateUserPermissions(
        selectedUser, selectedSystemIds,

      );
      console.log("User permissions updated!");
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error("Error updating user permissions:", error);
      setError("Failed to update user permissions.");
    } finally {
      setIsLoading(false);
    }
  };

  // Render loading state or the main content
  if (isLoading) {
    return <Card>Loading...</Card>;
  }

  return (
    <Card className="will-card">
      <Card.Header as="h4">Manage Users</Card.Header>
      <Card.Body>
        {error && <p className="text-danger">{error}</p>}
        <Card.Title as="h6">Select User:</Card.Title>
        <Form.Select
          className="mb-3"
          data-bs-theme="dark"
          value={selectedUser ? selectedUser.email : ""}
          onChange={handleSelectChange}
        >
          <option value="">Select User</option>
          {users.map((user) => (
            <option key={user.id} value={user.email}>
              {user.email}
            </option>
          ))}
        </Form.Select>

        <Card.Title as="h6">Select Fields User Can Access:</Card.Title>
        <Row style={{ width: "100%" }}>
          {systems.map((system) => (
            <Col key={system.id} md={6}>
              <Form.Check
                type="checkbox"
                className="will-check"
                label={system.name}
                name={system.name}
                onChange={() => handleCheckboxChange(system.id)}
                checked={system.selected || false} // Default to false if selected is undefined
              />
            </Col>
          ))}
        </Row>
        <Button
          onClick={handleSubmit}
          className="my-button-style-will"
          disabled={isLoading}
        >
          Submit
        </Button>
      </Card.Body>
    </Card>
  );
}

export default ManagePermissions;