import React from "react";
import { Row } from "react-bootstrap";
import InviteUser from "./InviteUser";
import ManageUsers from "./ManageUsers";
import "../Css/users.css";

const Account = () => {
  return (
    <Row>
      <InviteUser />
      <ManageUsers />
    </Row>
  );
};

export default Account;
