import React, { useState, useEffect } from "react";
import ScheduleForm from "./ScheduleForm";
import ScheduleList from "./ScheduleList";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import SystemInfoContext from "../../FieldStatus/Context/SystemInfoContext";


const Scheduler = () => {
  const systemInfo = useContext(SystemInfoContext);

  const iotName = systemInfo.IOTThingName || "";
  const [schedules, setSchedules] = useState([]);

  const fetchSchedules = async () => {
    try {
      const response = await axios.get(
        `https://j3pp969u1j.execute-api.us-west-2.amazonaws.com/prod/retrieve_schedules`,
        {
          params: { iotName },
        }
      );
      setSchedules(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching schedules:", error);
      setSchedules([]);
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, [iotName]);

  const addSchedule = async (newSchedule) => {
    try {
      await axios.post(
        `https://j3pp969u1j.execute-api.us-west-2.amazonaws.com/prod/create_schedule`,
        newSchedule
      );
      fetchSchedules(); // Reload schedules after adding a new one
    } catch (error) {
      console.error("Error adding schedule:", error);
    }
  };

  return (
    <>
      <ScheduleForm
        addSchedule={addSchedule}
        fetchSchedules={fetchSchedules}
        systemInfo={systemInfo}
      />
      <ScheduleList
        schedules={schedules}
        iotName={iotName}
        fetchSchedules={fetchSchedules}
      />
    </>
  );
};

export default Scheduler;
