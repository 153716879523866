import { useState, useEffect } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { SystemUser, System } from "../../models";

export function useFetchSystems(userId) {
  const [systems, setSystems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userSystemRecords, setUserSystemRecords] = useState([]);

  useEffect(() => {
    // If we don't have a userId yet, skip fetching
    if (!userId) return;

    async function fetchSystems() {
      try {
        setLoading(true);

        // 1) Fetch all SystemUser records
        const allSystemUsers = await DataStore.query(SystemUser);
        const userSystemRecords = allSystemUsers.filter(su => su.userID === userId);
        setUserSystemRecords(userSystemRecords);

        // 2) Extract system IDs
        const userSystemIds = userSystemRecords.map(su => su.systemID);

        // 3) Fetch all System records
        const allSystems = await DataStore.query(System);
        const userSystems = allSystems.filter(system => userSystemIds.includes(system.id));
        setSystems(userSystems);

      } catch (err) {
        console.error("Error fetching fields:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    fetchSystems();
  }, [userId]);

  return { systems, loading, error };
}