import { DataStore } from "@aws-amplify/datastore";
import { User, SystemUser } from "../../../models";
import HandleSignUp from "../../../shared/utils/HandleSignUp";

// Single system logic for Users tab.

export const inviteUserToSystem = async (email, systemInfo) => {
    try {
        const userExists = await DataStore.query(User, (u) => u.email.eq(email));
        if (!userExists.length) {
            const userDetails = {
                email,
                TemporaryPassword: "Initial1!", // This should ideally be generated securely
            };

            const response = await HandleSignUp(userDetails);
            console.log("Cognito user creation response:", response);

            const newUser = await DataStore.save(new User({ email: email }));

            await DataStore.save(
                new SystemUser({ systemID: systemInfo.id, userID: newUser.id })
            );

            alert("User created and linked to the field successfully!");
        } else {
            alert(email + " is already in the system. Please enter a new user.");
        }
    } catch (error) {
        console.error("Failed to create user:", error);
        alert("Failed to create user: " + error.message);
    }
};

export const fetchUsersToSystem = async (systemID) => {
    try {
        const systemUsers = await DataStore.query(SystemUser, (su) =>
            su.systemID.eq(systemID)
        );
        const users = await Promise.all(
            systemUsers.map(async (su) => {
                const user = await DataStore.query(User, su.userID);
                return { ...user, role: su.role };
            })
        );
        return users;
    } catch (error) {
        console.error("Error fetching users for the system:", error);
    }

}

export const deleteSystemUserRelationship = async (user, systemID) => {
    try {
        const systemUser = await DataStore.query(SystemUser, (su) =>
            su.userID.eq(user.id || systemID)
        );
        await DataStore.delete(systemUser);
    } catch (error) {
        console.error("Error deleting user:", error);
    }
}

export const handleUpdateUserRole = async (user, role, system) => {
    try {
        if (!user || !role) return;

        const [systemUserRecord] = await DataStore.query(SystemUser, (su) =>
            su.systemID.eq(system.id)
        );

        if (systemUserRecord) {
            await DataStore.save(
                SystemUser.copyOf(systemUserRecord, (updated) => {
                    updated.role = role;
                })
            );
            alert(`Updated ${user.email} to role "${role}" successfully!`);
        } else {
            alert("SystemUser record not found – cannot update role.");
        }
    } catch (error) {
        console.error("Error updating user role:", error);
        alert("Failed to update user role: " + error.message);
    }
};