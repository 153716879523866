import React from 'react';
import { Flex, useTheme, Image } from "@aws-amplify/ui-react";
import willCloud from "shared/images/willcloud-logo-white.png";

/**
 * Defines the header element of the authentication screen
 */
function Header() {
  const { tokens } = useTheme();

  return (
    <Flex paddingBottom={tokens.space.medium} paddingTop={tokens.space.medium}>
      <Image
        alt="logo"
        src={willCloud}
        style={{ maxWidth: "1650px", maxHeight: "24px" }}
      />
      <Flex className="header-shape" />
    </Flex>
  );
}

export default Header;
