import React from "react";
import { Card, Button, Row } from "react-bootstrap";

const ControlPanel = ({
  zone = "", // default to an empty string if undefined
  values,
  handleControlButtonClick,
  isButtonSelected,
  type,
}) => {
  function capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  // Use a "safe" zone so that undefined is converted to an empty string
  const safeZone = zone || "";

  // Determine if the zone should be displayed or not
  const displayZone = safeZone ? ` - ${safeZone}` : "";

  // Check if the type should be rendered for the current zone
  // (for example, if you never want to show buttons for "Security")
  const shouldRenderButtons = safeZone !== "Security";

  return (
    shouldRenderButtons && (
      <Card.Body>
        <Card.Title as="h6" style={{ color: "white" }}>
          {`${type}${displayZone}`}
        </Card.Title>
        <Row style={{ justifyContent: "center", width: "110%" }}>
          {values.map((val, index) => (
            <Button
              key={`${type}-${safeZone ? `${safeZone}-` : ""}${index}`} // safeZone is now never undefined
              className={`button-style ${isButtonSelected(safeZone, type, val) ? "selected-button" : ""
                }`}
              onClick={() => handleControlButtonClick(safeZone, type, val)}
            >
              {val}
            </Button>
          ))}
        </Row>
        <Button
          key={`no-${type}-${safeZone}`}
          onClick={() =>
            handleControlButtonClick(safeZone, type, `No ${type}`)
          }
          className={`no-blank ${isButtonSelected(safeZone, type, `No ${type}`) ? "selected-button" : ""
            }`}
        >
          No {capitalizeFirstLetter(type)}
        </Button>
      </Card.Body>
    )
  );
};

export default ControlPanel;