import React, { useEffect } from "react";
import { useColorWheelState } from "@react-stately/color";
import { parseColor } from "@react-stately/color";
import { Card, Button } from "react-bootstrap";
import ColorWheel from "../Utils/ColorWheelUtil";

function ColorWheelSelector({
  zone,
  RGBCommands,
  handleColorButtonClick,
  isButtonSelected,
  selectedColors,
  hasRGBDimmers,
}) {
  // Initialize the color wheel state using an initial color computed from the zone & selectedColors.
  const state = useColorWheelState({
    defaultValue: parseColor(initialColor(zone, selectedColors)),
  });

  useEffect(() => {
    const newColor = initialColor(zone, selectedColors);
    state.setValue(parseColor(newColor));
  }, [zone, selectedColors]);

  // Compute the initial color for the wheel.
  function initialColor(zone, selectedColors) {
    const cleanZone = zone.replace(/[.\s]+/g, "");
    if (!selectedColors[cleanZone]) {
      selectedColors[cleanZone] = "255,255,0";
    }
    const selectedColorCode = selectedColors[cleanZone];
    const cleanColor = selectedColorCode.replace(/#/g, ""); // Remove any '#' characters.
    const rgbValues = cleanColor.split(",").map(Number);
    const [h, s, l] = rgbToHsl(...rgbValues);
    return `hsl(${h}, ${s}%, ${l}%)`;
  }

  // Helper function to convert RGB to HSL.
  function rgbToHsl(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }
    return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)];
  }

  // When the user finishes changing the color on the wheel,
  // apply the toggle logic:
  // If the selected color is already active (whether in its hashed or plain format),
  // send "0,0,0" (to clear it). Otherwise, send the hashed color value.
  function handleColorChangeEnd(rgbString) {
    const rgbValues = rgbString.match(/\d+/g);
    if (!rgbValues) {
      console.error("Invalid RGB string format:", rgbString);
      return;
    }
    const hashedColorString = rgbValues.map((v) => `#${v}`).join(",");
    const plainColorString = rgbValues.join(",");
    const commandExists =
      isButtonSelected(zone, "color", hashedColorString) ||
      isButtonSelected(zone, "color", plainColorString);

    if (!commandExists) {
      handleColorButtonClick(zone, RGBCommands, plainColorString);
    } else {
      handleColorButtonClick(zone, RGBCommands, hashedColorString);
    }
  }

  // Predefined color buttons for Red, Green, and Blue with toggle logic.
  const handleRedClick = () => {
    const plain = "255,0,0";
    const hashed = "#255,0,0";
    const commandExists =
      isButtonSelected(zone, "color", plain) ||
      isButtonSelected(zone, "color", hashed);
    if (!commandExists) {
      // If no command is active, send the plain value.
      handleColorButtonClick(zone, RGBCommands, plain);
    } else {
      // If a command is active, toggle by sending the hashed value.
      handleColorButtonClick(zone, RGBCommands, hashed);
    }
  };

  const handleGreenClick = () => {
    const plain = "0,255,0";
    const hashed = "#0,255,0";
    const commandExists =
      isButtonSelected(zone, "color", plain) ||
      isButtonSelected(zone, "color", hashed);
    if (!commandExists) {
      handleColorButtonClick(zone, RGBCommands, plain);
    } else {
      handleColorButtonClick(zone, RGBCommands, hashed);
    }
  };

  const handleBlueClick = () => {
    const plain = "0,0,255";
    const hashed = "#0,0,255";
    const commandExists =
      isButtonSelected(zone, "color", plain) ||
      isButtonSelected(zone, "color", hashed);
    if (!commandExists) {
      handleColorButtonClick(zone, RGBCommands, plain);
    } else {
      handleColorButtonClick(zone, RGBCommands, hashed);
    }
  };

  return (
    <Card.Body>
      <Card.Title as="h6" style={{ color: "white" }}>
        Color Selector
      </Card.Title>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <ColorWheel
          value={state.value}
          onChangeEnd={(color) =>
            handleColorChangeEnd(color.toString("rgb"))
          }
          style={{ maxWidth: "200px" }}
        />
      </div>

      {/* Two buttons on top */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
          gap: "10px",
          width: "100%",
        }}
      >
        <Button
          className={`button-style ${isButtonSelected(zone, "color", "255,0,0") ||
            isButtonSelected(zone, "color", "#255,#0,#0")
            ? "selected-button"
            : ""
            }`}
          onClick={handleRedClick}
        >
          Red
        </Button>
        <Button
          className={`button-style ${isButtonSelected(zone, "color", "0,255,0") ||
            isButtonSelected(zone, "color", "#0,#255,#0")
            ? "selected-button"
            : ""
            }`}
          onClick={handleGreenClick}
        >
          Green
        </Button>
      </div>

      {/* One button on the bottom */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          width: "100%",
        }}
      >
        <Button
          className={`button-style ${isButtonSelected(zone, "color", "0,0,255") ||
            isButtonSelected(zone, "color", "#0,#0,#255")
            ? "selected-button"
            : ""
            }`}
          onClick={handleBlueClick}
        >
          Blue
        </Button>
      </div>

      {/* "No Color" Button - toggles between "#0,#0,#0" and "0,0,0" */}
      {!hasRGBDimmers && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            width: "100%",
          }}
        >
          <Button
            key={`color-no-${zone}`}
            className={`no-blank ${(isButtonSelected(zone, "color", "#0,#0,#0") ||
              isButtonSelected(zone, "color", "0,0,0"))
              ? "selected-button"
              : ""
              }`}
            onClick={() => {
              const commandExists =
                isButtonSelected(zone, "color", "#0,#0,#0") ||
                isButtonSelected(zone, "color", "0,0,0");
              if (!commandExists) {
                handleColorButtonClick(zone, RGBCommands, "0,0,0");
              } else {
                handleColorButtonClick(zone, RGBCommands, "#0,#0,#0");
              }
            }}
          >
            No Color
          </Button>
        </div>
      )}
    </Card.Body>
  );
}

export default ColorWheelSelector;