import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import useSignOut from "shared/hooks/useSignOut";
import AppContent from "./AppContent"; // ✅ Add back the main app content
import { withAuthenticator } from "@aws-amplify/ui-react"; // ✅ Import withAuthenticator

import Header from "Components/Layout/Components/Header";
import Footer from "Components/Layout/Components/Footer";
import SignInHeader from "Components/Login/Components/SignInHeader";
import SignInFooter from "Components/Login/Components/SignInFooter";
import SignUpHeader from "Components/Login/Components/SignUpHeader";
import SignUpFooter from "Components/Login/Components/SignUpFooter";

function Home() {
  const signOut = useSignOut();

  return (
    <div>
      <h1>Home Page - You are logged in</h1>
      <button onClick={() => signOut()}>Sign Out</button>
    </div>
  );
}

function Login() {
  return (
    <Authenticator>
      {({ user }) => (user ? <Navigate to="/" /> : <h1>Loading...</h1>)}
    </Authenticator>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <BrowserRouter>
        <Authenticator>
          {({ user }) =>
            user ? (
              <Routes>
                <Route path="/*" element={<AppContent />} /> {/* ✅ Add AppContent */}
              </Routes>
            ) : (
              <Login />
            )
          }
        </Authenticator>
      </BrowserRouter>
    </Authenticator.Provider>
  );
}

export default withAuthenticator(App, {
  components: {
    Header,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter,
    },
    ConfirmSignUp: {
      Header: SignUpHeader,
      Footer: SignUpFooter,
    },
    Footer,
  },
  hideSignUp: true,
  className: "custom-sign-in", // ✅ Apply the custom CSS class
});