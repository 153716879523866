// src/features/Controls/Components/LightingControlViews.js
import React, { useState, useEffect } from "react";
import { Row, Alert, Card, Button, Collapse } from "react-bootstrap";
import { FaMinus, FaPlus } from "react-icons/fa";
import DimmerControl from "./DimmerControl";
import ColorSelector from "./ColorSelector";
import ControlPanel from "./ControlPanel";
import ColorWheelSelector from "./ColorWheel";
import OfflinePage from "../../Offline/Components/Offline";
import { DataStore } from "@aws-amplify/datastore";
import { Zone } from "../../../models";
import { useContext } from "react";
import SystemInfoContext from "../../FieldStatus/Context/SystemInfoContext";

function LightingControlsView({
  isDeviceOnline,
  hasActiveScene = {},
  selectedColors,
  shadowState,
  handleDimmerButtonClick,
  handleColorButtonClick,
  handleControlButtonClick,
  isButtonSelected,
}) {
  const systemInfo = useContext(SystemInfoContext);
  const [zonesData, setZonesData] = useState([]); // State to hold fetched zones
  const [collapsedZones, setCollapsedZones] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if systemInfo is defined
        if (!systemInfo || !systemInfo.id) {
          //console.warn("systemInfo is undefined or missing id. Unable to fetch zones.");
          setZonesData([]);
          setCollapsedZones([]);
          return;
        }

        // Fetch all Zones from DataStore
        const fetchedZones = await DataStore.query(Zone);

        // Filter for zones belonging to the current system
        const filteredZones = fetchedZones.filter(
          (zone) => zone.systemID === systemInfo.id
        );


        // Map to update zones as needed
        const updatedZones = filteredZones.map((zone) => {
          // Make a copy so we don’t mutate the original
          let newZone = { ...zone };

          // Check white values
          if (!Array.isArray(newZone.whiteValues) || newZone.whiteValues.length === 0) {
            newZone.whiteValues = ["0", "38", "128", "255"];
          }

          // Check color values
          if (newZone.hasColor && (!Array.isArray(newZone.colorValues) || newZone.colorValues.length === 0)) {
            newZone.colorValues = [
              "255,0,0",
              "0,255,0",
              "0,0,255",
              "255,255,0",
              "0,255,255",
              "255,1,0",
              "255,0,1",
              "255,0,255",
            ];
          }

          // Check color dimmer values
          if (
            newZone.hasColorDimmers &&
            newZone.colorDimmerValues &&
            newZone.colorDimmerValues.length === 0
          ) {
            newZone.colorDimmerValues = ["0", "38", "128", "255"];
          }

          return newZone;
        });

        // Sort zones:
        // - Zones that are NOT numbered (i.e. the name does not start with a digit) come first.
        // - Zones that ARE numbered are sorted in ascending order based on the number at the start of the name.
        const sortedZones = updatedZones.sort((a, b) => {
          // Attempt to extract a leading number from the zone name.
          const aMatch = a.name.match(/^\d+/);
          const bMatch = b.name.match(/^\d+/);
          const aNum = aMatch ? parseInt(aMatch[0], 10) : null;
          const bNum = bMatch ? parseInt(bMatch[0], 10) : null;

          // Both zones are unnumbered
          if (aNum === null && bNum === null) return 0;

          // If 'a' is unnumbered, it should come first.
          if (aNum === null) return -1;

          // If 'b' is unnumbered, it should come first.
          if (bNum === null) return 1;

          // Both zones are numbered; sort in ascending order.
          return aNum - bNum;
        });

        setZonesData(sortedZones); // Use sortedZones instead of filteredZones
        setCollapsedZones(Array(sortedZones.length).fill(false)); // Match the updated zones list
      } catch (error) {
        console.error("Error fetching zones:", error);
      }
    };

    fetchData();
  }, [systemInfo]); // ✅ Added systemInfo as a dependency

  // Check if the device is offline and display the message if so
  if (isDeviceOnline.isDeviceOnline === "Offline") {
    return <OfflinePage />; // Show offline message
  }

  // Check if shadowState is still loading
  if (
    Object.keys(shadowState).length === 0 ||
    isDeviceOnline.isDeviceOnline === "Establishing Connection..."
  ) {
    return <div>Loading controls...</div>;
  }

  // Collapse panel toggle function
  const toggleCollapse = (index) => {
    const newCollapsedZones = [...collapsedZones];
    newCollapsedZones[index] = !newCollapsedZones[index];
    setCollapsedZones(newCollapsedZones);
  };

  // A function to render a ControlPanel, ensuring that if a scene is active,
  // only the "No Scene" button is enabled.
  const renderSceneControlPanel = (zoneName, sceneValues, disabled, formattedZone) => {
    return (
      <ControlPanel
        zone={zoneName}
        values={sceneValues || []}
        handleControlButtonClick={handleControlButtonClick}
        isButtonSelected={isButtonSelected}
        type="Scene"
        // Disable all scene buttons if active, except "No Scene"
        customDisabledCheck={(value) => {
          // If a scene is active and the current button is not "No Scene", disable it.
          if (hasActiveScene[formattedZone] && value !== "No Scene") {
            return true;
          }
          return false;
        }}
      />
    );
  };

  // Render global control panels (Scenes, Patterns, etc.)
  const renderControlPanels = (types) =>
    types.map(({ type, valuesKey, hasRadioKey }, index) => {
      const panelIndex = zonesData.length + index;
      const controlValues = systemInfo[valuesKey] || [];

      return (
        !systemInfo[hasRadioKey] &&
        controlValues.length > 0 && (
          <Card
            key={`zone-container-${type.toLowerCase()}`}
            className="zone-container"
            style={{ minWidth: "300px", width: "400px" }}
          >
            <Card.Header as="h4">
              All Zones
              <Button
                variant="link"
                className="collapse-button"
                onClick={() => toggleCollapse(panelIndex)}
                style={{ float: "right" }}
                aria-label="collapse zone"
              >
                {collapsedZones[panelIndex] ? (
                  <FaPlus style={{ fontSize: "30px", color: "white" }} />
                ) : (
                  <FaMinus style={{ fontSize: "30px", color: "white" }} />
                )}
              </Button>
            </Card.Header>
            <Collapse in={!collapsedZones[panelIndex]}>
              <div>
                <ControlPanel
                  values={controlValues}
                  handleControlButtonClick={handleControlButtonClick}
                  isButtonSelected={isButtonSelected}
                  type={type}
                  // Global panels are never completely disabled since they're not zone-based.
                  disabled={false}
                />
              </div>
            </Collapse>
          </Card>
        )
      );
    });

  return (
    <Row className="justify-content-center align-items-center">
      {zonesData.map((zoneData, zoneIndex) => {
        const zoneName = zoneData.name;
        const formattedZone = zoneName.replace(/\s+/g, "");
        const levelRGBCommand = zoneData.levelRGBCommand || "";
        const RGBCommand = zoneData.colorCommand || "";
        const RGBValues = zoneData.colorValues || [];
        const hasRadioScenes = zoneData.hasScene || false;
        const hasColorWheel = zoneData.hasColorWheel || false;
        const hasRGBDimmers = levelRGBCommand.length > 0;
        const sceneIsActive = hasActiveScene[formattedZone];

        return (
          <Card
            key={`zone-container-${zoneIndex}`}
            className="zone-container"
            style={{ minWidth: "300px", width: "400px" }}
          >
            <Card.Header>
              <h4 className="field-card-header">{zoneName}</h4>
              <Button
                variant="link"
                className="collapse-button"
                onClick={() => toggleCollapse(zoneIndex)}
                style={{ float: "right" }}
              >
                {collapsedZones[zoneIndex] ? (
                  <FaPlus style={{ fontSize: "30px", color: "white" }} />
                ) : (
                  <FaMinus style={{ fontSize: "30px", color: "white" }} />
                )}
              </Button>
            </Card.Header>
            <Collapse in={!collapsedZones[zoneIndex]}>
              <div>
                {sceneIsActive && hasRadioScenes && (
                  <Alert
                    variant="warning"
                    style={{
                      background: "#222",
                      color: "white",
                      borderColor: "transparent",
                    }}
                  >
                    Scene Active - Manual Controls are Disabled for this zone.
                    Use "No Scene" to re-enable.
                  </Alert>
                )}

                {!sceneIsActive && zoneData.hasWhite && (
                  <DimmerControl
                    zone={zoneName}
                    dimmerValues={zoneData.whiteValues}
                    dimmerCommands={zoneData.whiteCommand}
                    handleDimmerButtonClick={handleDimmerButtonClick}
                    isButtonSelected={isButtonSelected}
                    type="White"
                    disabled={sceneIsActive}
                  />
                )}
                {!sceneIsActive && hasRGBDimmers && (
                  <DimmerControl
                    zone={zoneName}
                    dimmerValues={zoneData.RGBDimmerValues}
                    dimmerCommands={zoneData.RGBDimmerCommand}
                    handleDimmerButtonClick={handleDimmerButtonClick}
                    isButtonSelected={isButtonSelected}
                    type="Rgb"
                    disabled={sceneIsActive}
                  />
                )}
                {!sceneIsActive && zoneData.hasColor && (
                  hasColorWheel ? (
                    <ColorWheelSelector
                      zone={zoneName}
                      RGBValues={RGBValues}
                      RGBCommands={RGBCommand}
                      handleColorButtonClick={handleColorButtonClick}
                      isButtonSelected={isButtonSelected}
                      selectedColors={selectedColors}
                      disabled={sceneIsActive}
                      hasRGBDimmers={hasRGBDimmers}
                    />
                  ) : (
                    <ColorSelector
                      zone={zoneName}
                      RGBValues={RGBValues}
                      RGBCommands={RGBCommand}
                      handleColorButtonClick={handleColorButtonClick}
                      isButtonSelected={isButtonSelected}
                      selectedColors={selectedColors}
                      disabled={sceneIsActive}
                    />
                  )
                )}

                {/* Scene Panel */}
                {zoneData.hasScene && renderSceneControlPanel(
                  zoneName,
                  zoneData.sceneValues,
                  sceneIsActive,
                  formattedZone
                )}

                {/* Other Panels: If scene is active, these should be disabled */}
                {zoneData.hasPattern && (
                  <ControlPanel
                    zone={zoneName}
                    values={zoneData.patternValues || []}
                    handleControlButtonClick={handleControlButtonClick}
                    isButtonSelected={isButtonSelected}
                    type="Pattern"
                    disabled={sceneIsActive}
                  />
                )}
                {zoneData.hasShow && (
                  <ControlPanel
                    zone={zoneName}
                    values={zoneData.showValues || []}
                    handleControlButtonClick={handleControlButtonClick}
                    isButtonSelected={isButtonSelected}
                    type="Show"
                    disabled={sceneIsActive}
                  />
                )}
              </div>
            </Collapse>
          </Card>
        );
      })}

      {renderControlPanels([
        { type: "Scene", valuesKey: "sceneValues", hasRadioKey: "radioScenes" },
        { type: "Show", valuesKey: "showValues", hasRadioKey: "radioShows" },
        { type: "Pattern", valuesKey: "patternValues", hasRadioKey: "radioPatterns" },
        { type: "Effect", valuesKey: "effectValues", hasRadioKey: "radioEffects" },
        { type: "Music", valuesKey: "musicValues", hasRadioKey: "radioMusic" },
        { type: "Schedule", valuesKey: "scheduleValues", hasRadioKey: "radioSchedule" },
      ])}
    </Row>
  );
}

export default LightingControlsView;