// src/Features/Scheduler/Components/ScheduleList.js

import React, { useState } from "react";
import { Table, Button, Modal, Form, Card } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { deleteSchedule, updateSchedule } from "../Utils/api"; // Adjust the path as needed

const ScheduleList = ({ schedules, iotName, fetchSchedules }) => {
  const userTimeZone = moment.tz.guess(); // Guess the user's time zone
  const [showEditModal, setShowEditModal] = useState(false);
  const [editSchedule, setEditSchedule] = useState({});

  const handleEdit = (schedule) => {
    setEditSchedule(schedule);
    setShowEditModal(true);
  };

  const handleDelete = async (scheduleId) => {
    try {
      await deleteSchedule(iotName, scheduleId);
      fetchSchedules(); // Reload schedules after deleting one
    } catch (error) {
      console.error("Error deleting schedule:", error);
      // Optionally, handle error UI here
    }
  };

  const handleSave = async () => {
    try {
      await updateSchedule(editSchedule);
      fetchSchedules(); // Reload schedules after editing one
      setShowEditModal(false);
    } catch (error) {
      console.error("Error updating schedule:", error);
      // Optionally, handle error UI here
    }
  };

  const formatEpochToLocal = (epochTime) => {
    return moment.unix(epochTime).tz(userTimeZone).format("YYYY-MM-DD HH:mm");
  };

  if (!schedules || schedules.length < 1) {
    return <></>; // Or render a message indicating no schedules
  }

  return (
    <Card className="scheduler-card">
      {/* Schedule Table */}
      <Table className="table-custom" responsive="md">
        <thead>
          <tr>
            <th>Name</th>
            <th>Start Date/Time</th>
            <th>End Date/Time</th>
            <th>Area</th>
            <th>Action</th>
            <th>Repeat</th>
            <th>Repeat Days</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {schedules.map((schedule, index) => (
            <tr key={schedule.ScheduleID || index} className="table-row-underline">
              <td>{schedule.Name}</td>
              <td>{formatEpochToLocal(schedule.StartDate)}</td>
              <td>{formatEpochToLocal(schedule.EndDate)}</td>
              <td>{schedule.SelectedArea}</td>
              <td>{schedule.SelectedAction}</td>
              <td>{schedule.Repeat ? "Yes" : "No"}</td>
              <td>
                {schedule.RepeatDays ? schedule.RepeatDays.join(", ") : ""}
              </td>
              <td>
                <Button
                  variant="outline-danger"
                  className="remove-button"
                  onClick={() => handleDelete(schedule.ScheduleID)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default ScheduleList;