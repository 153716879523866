import React from "react";
import { Card, Button, Row } from "react-bootstrap";

const DimmerControl = ({
  zone,
  dimmerValues,
  dimmerCommands,
  handleDimmerButtonClick,
  isButtonSelected,
  type,
}) => {
  const getButtonLabel = (percentageValue) => {
    if (percentageValue === 0) return "Off";
    if (percentageValue === 100) return "On";
    return `${percentageValue}%`;
  };

  return (
    <Card.Body>
      <Card.Title as="h6" style={{ color: "white" }}>
        {type} Light Level
      </Card.Title>
      <Row style={{ justifyContent: "center", width: "110%" }}>
        {dimmerValues.length === 2
          ? dimmerValues.map((value, index) => {
            const percentageValue = Math.round((value / 255) * 100);
            return (
              <Button
                key={`dimmer-button-${type}-${zone}-${index}`}
                className={`button-style ${isButtonSelected(zone, type, percentageValue)
                  ? "selected-button"
                  : ""
                  }`}
                onClick={() =>
                  handleDimmerButtonClick(
                    zone,
                    type,
                    dimmerCommands,
                    percentageValue
                  )
                }
              >
                {getButtonLabel(percentageValue)}
              </Button>
            );
          })
          : dimmerValues.map((value, index) => {
            const percentageValue = Math.round((value / 255) * 100);
            return (
              <Button
                key={`dimmer-button-${type}-${zone}-${index}`}
                className={`button-style ${isButtonSelected(zone, type, percentageValue)
                  ? "selected-button"
                  : ""
                  }`}
                onClick={() =>
                  handleDimmerButtonClick(
                    zone,
                    type,
                    dimmerCommands,
                    percentageValue
                  )
                }
              >
                {percentageValue}%
              </Button>
            );
          })}
      </Row>
    </Card.Body>
  );
};

export default DimmerControl;
