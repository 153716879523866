// useGetUser.js
import { useState, useEffect, useMemo } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useFetchSystems } from "./useFetchSystems"; // Adjust path as needed
import { User } from "../../models";

/**
 * Hook that:
 * 1) Subscribes to the User record matching the current authenticated user's email.
 * 2) Waits until DataStore is synced (isSynced === true) before resolving the user data.
 * 3) Fetches related systems once the user is loaded.
 */
export function useGetUser() {
    const [user, setUser] = useState(null);
    const [loadingUser, setLoadingUser] = useState(true);
    const [error, setError] = useState(null);

    // 1) ObserveQuery for the current user once we know the user’s email.
    useEffect(() => {
        let subscription;
        let cancelled = false; // To handle potential unmount during async

        async function subscribeToUser() {
            try {
                // Get the authenticated user's attributes
                const authUser = await fetchUserAttributes();
                const email = authUser.email;

                // ObserveQuery runs immediately and also re-runs whenever
                // the local DataStore changes for this model + filter.
                // It also indicates when the local data is in sync with the backend.
                subscription = DataStore.observeQuery(User, (u) => u.email.eq(email)).subscribe(
                    ({ items, isSynced }) => {
                        if (cancelled) return;

                        // Once isSynced == true, we know DataStore has pulled
                        // all matching User records from the server (if any).
                        if (isSynced) {
                            if (items.length > 0) {
                                // We found a matching user in DataStore
                                setUser(items[0]);
                            } else {
                                // User doesn't exist in DataStore
                                console.error(`No user found in DataStore for email: ${email}`);
                                setError(`No user found in DataStore for email: ${email}`);
                            }

                            // Done loading (synced + items are final)
                            setLoadingUser(false);
                        }
                    }
                );
            } catch (err) {
                console.error("Error subscribing to user info:", err);
                setError(err.message || "An error occurred while fetching user information.");
                setLoadingUser(false);
            }
        }

        subscribeToUser();

        // Cleanup subscription on unmount
        return () => {
            cancelled = true;
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, []);

    // 2) Fetch related systems using a custom hook (once we have the user)
    const { systems, loading: loadingSystems, error: systemsError } = useFetchSystems(
        user ? user.id : null
    );

    // 3) Combine user and systems data only if both are available
    const userWithSystems = useMemo(() => {
        if (!user) return null;
        return { ...user, systems };
    }, [user, systems]);

    // 4) Consolidate loading and error states
    const loading = loadingUser || loadingSystems;
    const combinedError = error || systemsError;

    // 5) Return the stable object and combined loading/error
    return {
        user: userWithSystems,
        loading,
        error: combinedError,
    };
}