export function log(level, message) {
  const url =
    "https://59gnydu8qb.execute-api.us-west-2.amazonaws.com/beta/willcloud_logging_test_2";
  const meta = {
    agent: navigator.userAgent,
    language: navigator.language,
    platform: navigator.platform,
    level: level,
    source: "WiLLCloud",
  };

  fetch(url, {
    mode: "no-cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ meta, message }),
  }).catch((e) => console.error("Error sending log:", e));
}
