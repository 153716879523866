// /Users/nickmarkel/Documents/willcloud/src/Features/Navbar/hooks/useIsUser.js
import { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { SystemUser } from '../../../models';
import { useLocation } from 'react-router-dom';

/**
 * Custom hook to determine if the current user has the role of "user".
 *
 * @returns {Object} - Returns an object with `isUser` (boolean) and `loading` (boolean).
 */
const useIsUser = () => {
    const [isUser, setIsUser] = useState(false);
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    // Ensure you have systemInfo in location.state to avoid errors.
    const systemID = location.state?.systemInfo?.id;

    useEffect(() => {
        const fetchUserRole = async () => {
            try {
                // Query all SystemUser records
                const systemUsers = await DataStore.query(SystemUser);

                // Find the record corresponding to the current system
                const userSystem = systemUsers.find((systemUser) => systemUser.systemID === systemID);

                if (userSystem && userSystem.role === 'User') {
                    setIsUser(true);
                } else {
                    setIsUser(false);
                }
            } catch (error) {
                console.error("Error fetching user role:", error);
                setIsUser(false);
            } finally {
                setLoading(false);
            }
        };

        if (systemID) {
            fetchUserRole();
        } else {
            // In case systemID is not available, you might decide to stop loading.
            setLoading(false);
        }
    }, [systemID]);

    return { isUser, loading };
};

export default useIsUser;