import React, { useState, useEffect, useContext } from "react";
import { Card, Container, Spinner, ListGroup, Button } from "react-bootstrap";
import { FaCog } from "react-icons/fa";
import "@aws-amplify/ui-react/styles.css";
import { fetchUsersToSystem, deleteSystemUserRelationship, handleUpdateUserRole } from "../Utils/userManUtils";
import "../Css/users.css";
import SystemInfoContext from "../../FieldStatus/Context/SystemInfoContext";
import UserSettingsModal from "../Components/UserSettingsModal";

function ManageUsers() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [userToModify, setUserToModify] = useState(null);

  const systemInfo = useContext(SystemInfoContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await fetchUsersToSystem(systemInfo.id);
        // Filter out users with "@willbrands.com" in their email
        const filteredUsers = result.filter(
          (user) => !user.email.includes("@willbrands.com")
        );
        setUsers(filteredUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (systemInfo?.id) {
      fetchData();
    }
  }, [systemInfo?.id]);

  // -- Show the user modal (cog icon click) --
  const handleShowUserModal = (user) => {
    setUserToModify(user);
    setShowUserModal(true);
  };

  // -- Hide/close the modal --
  const handleCloseUserModal = () => {
    setShowUserModal(false);
    setUserToModify(null);
    setSelectedRole("");
  };

  // -- Delete the user from the system --
  const handleDeleteUser = async () => {
    if (!userToModify) return;
    try {
      await deleteSystemUserRelationship(userToModify, systemInfo.id);
      // Remove user from local state
      setUsers(users.filter((u) => u.id !== userToModify.id));
      handleCloseUserModal();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleSelectUser = (userId) => {
    const user = users.find((u) => u.id === userId);
    setSelectedUser(user);
  };

  if (isLoading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
        <span className="ml-2">Fetching Users...</span>
      </Container>
    );
  }

  return (
    <>
      <Card className="card-custom-width">
        <Card.Header as="h4">Manage Users</Card.Header>
        <Card.Body>
          <ListGroup style={{ width: "100%" }}>
            {users.map((user) => (
              <ListGroup.Item
                key={user.id}
                onClick={() => handleSelectUser(user.id)}
                className="user-list-item d-flex justify-content-between align-items-center"
              >
                <span className="user-email">{user.email}</span> {user.role}
                <Button
                  variant="outline-secondary"
                  className="cog-button"
                  style={{ fontSize: "1.1rem" }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent selecting the entire list item
                    handleShowUserModal(user);
                  }}
                >
                  <FaCog style={{ color: "white" }} />
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>

      {/* Renders the settings modal for changing role and deleting the user. */}
      <UserSettingsModal
        systemInfo={systemInfo}
        show={showUserModal}
        onHide={handleCloseUserModal}
        userToModify={userToModify}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        onUpdateUserRole={handleUpdateUserRole}
        onDeleteUser={handleDeleteUser}
      />
    </>
  );
}

export default ManageUsers;