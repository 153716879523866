import { pubsub } from "../../../shared/utils/Pubsub";

export const sendDimmingMessage = async (
  zone,
  type,
  command, // 'command' can be a string, empty array, or null
  value,
  thingName,
  fieldInfo
) => {
  try {
    // Validate 'value' to ensure it's a number between 0 and 100
    if (typeof value !== "number" || value < 0 || value > 100) {
      throw new Error(
        `Invalid value for dimming: ${value}. It must be a number between 0 and 100.`
      );
    }

    // Calculate the level based on the provided value
    const level = Math.round((value / 100) * 255);

    // Determine if 'command' is a non-empty string
    const isCommandValid = typeof command === "string" && command.trim() !== "";

    // Determine the effective command
    const effectiveCommand = isCommandValid
      ? command.trim()
      : ``; // Default command if none provided

    // Construct the messageValue based on the presence of a valid command
    const messageValue = isCommandValid ? `${effectiveCommand}#${level}` : `${level}`;

    // Construct the messageKey based on type and zone
    const messageKey = `level${type}${zone}`;

    // Define the MQTT topic for updating the shadow
    const UPDATE_TOPIC = `$aws/things/${thingName}/shadow/update`;

    // Construct the payload for the MQTT message
    const payload = {
      state: {
        desired: {
          [messageKey]: messageValue,
        },
      },
    };

    // Publish the message to the MQTT topic
    await pubsub.publish({ topics: UPDATE_TOPIC, message: payload });

  } catch (e) {
    // Log any errors that occur during the process
    console.error(`Send${type}DimmingMessage error for ${zone}:`, e);
  }
};

export const sendColorMessage = async (
  zone,
  command = null, // 'command' can be a string or null
  value,
  thingName,
  fieldInfo
) => {
  try {
    // Split the RGB values
    const rgbValues = value.split(",").map((v) => v.trim());

    // Determine if 'command' is a non-empty string
    const isCommandValid = typeof command === "string" && command.trim() !== "";

    // Determine the effective command
    const effectiveCommand = isCommandValid
      ? command.trim()
      : ``; // Default command if none provided

    // Construct the messageValue based on the presence of a valid command
    const messageValue = isCommandValid ? `${effectiveCommand}{${rgbValues.join(",")}}` : `${rgbValues.join(",")}`;

    // Construct the messageKey based on zone
    const messageKey = `valueRgb${zone}`;

    // Define the MQTT topic for updating the shadow
    const UPDATE_TOPIC = `$aws/things/${thingName}/shadow/update`;

    // Construct the payload for the MQTT message
    const payload = {
      state: {
        desired: {
          [messageKey]: messageValue,
        },
      },
    };

    // Publish the message to the MQTT topic
    await pubsub.publish({ topics: UPDATE_TOPIC, message: payload });


    // Optional: Log success message
    console.log(
      `Color message sent successfully for ${zone}: ${messageValue}`
    );
  } catch (e) {

  }
};

export const sendControlMessage = async (
  zone,
  type,
  value,
  thingName,
  fieldInfo
) => {
  try {
    const messageKey = `active${type}${zone}`;
    const messageValue = `${value}`;
    const UPDATE_TOPIC = `$aws/things/${thingName}/shadow/update`;

    const payload = {
      state: {
        desired: {
          [messageKey]: messageValue,
        },
      },
    };

    await pubsub.publish({ topics: UPDATE_TOPIC, message: payload }); // Corrected here
  } catch (e) {
    console.error(`Send${type}ControlMessage error:`, e);
  }
};
