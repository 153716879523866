// src/Features/Scheduler/Components/ScheduleForm.js

import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Col, Row, Dropdown, Alert, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { DataStore } from "@aws-amplify/datastore";
import { Zone } from "../../../models";

// Import your custom API function
import createSchedule from "../Utils/api/createSchedule";

import "../Css/scheduler.css";

// Context
import SystemInfoContext from "../../FieldStatus/Context/SystemInfoContext";

//TODO: Need to make schedule times back to 0-100 not 0-255

const getCurrentTime = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

const ScheduleForm = ({ addSchedule, fetchSchedules }) => {
  const systemInfo = useContext(SystemInfoContext);

  // State to hold fetched zones
  const [zonesData, setZonesData] = useState([]);

  // If you still have a separate Scenes array, keep it:
  const sceneValues = zonesData.sceneValues || [];
  const sceneCommands = zonesData.sceneCommands || [];

  const globalSceneValues = systemInfo.sceneValues || [];
  //const globalPatternValues = systemInfo.patternValues || [];

  // Form states
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState(getCurrentTime());
  const [endTime, setEndTime] = useState("23:59");
  const iotName = systemInfo.IOTThingName || "";
  const [selectedArea, setSelectedArea] = useState(""); // zone name or "Scenes"
  const [selectedAction, setSelectedAction] = useState("");
  const [repeat, setRepeat] = useState(false);
  const [repeatDays, setRepeatDays] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [availableActions, setAvailableActions] = useState([]); // State to hold actions

  // Optional: State for success message
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedZones = await DataStore.query(Zone);

        // Check if systemInfo.id is available
        if (!systemInfo.id) {
          console.warn("systemInfo.id is undefined. Unable to filter zones.");
          setZonesData([]);
          return;
        }

        // Filter zones where zone.systemID matches systemInfo.id
        const filteredZones = fetchedZones.filter(
          (zone) => zone.systemID === systemInfo.id
        );
        setZonesData(filteredZones);
      } catch (error) {
        console.error("Error fetching zones:", error);
      }
    };

    fetchData();
  }, [systemInfo.id]);

  // An array of zone names for the "Select Zone" dropdown
  const zoneNames = zonesData.map((zone) => zone.name);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const convertToEpochTime = (date, time) => {
    return Math.floor(
      moment.tz(`${date}T${time}:00`, moment.tz.guess()).valueOf() / 1000
    );
  };

  // Clear the form after saving
  const resetForm = () => {
    setName("");
    setStartDate(new Date());
    setEndDate(new Date());
    setStartTime(getCurrentTime());
    setEndTime("22:00");
    setSelectedArea("");
    setSelectedAction("");
    setRepeat(false);
    setRepeatDays([]);
  };

  const handleSave = async () => {
    // Reset success message on new save attempt
    setSuccessMessage("");

    // Input validation
    if (!name) {
      setErrorMessage("Name is required.");
      return;
    }
    if (!selectedArea) {
      setErrorMessage("Please select an area.");
      return;
    }
    if (!selectedAction) {
      setErrorMessage("Please select an action.");
      return;
    }

    setErrorMessage("");

    const startEpochTime = convertToEpochTime(
      moment(startDate).format("YYYY-MM-DD"),
      startTime
    );
    const endEpochTime = convertToEpochTime(
      moment(endDate).format("YYYY-MM-DD"),
      endTime
    );

    // Build the command (depends on your logic)
    let selectedCommand;
    if (selectedArea === "Scenes") {
      // The scene generally doesn't have a command but it's set to null either way.
      const sceneIndex = sceneValues.indexOf(selectedAction);
      selectedCommand = sceneCommands[sceneIndex];
    } else {
      // This takes care of finding the commands for white or colored lights. Will have to add commands for color dimming as well.
      // The commands are being phased out but we have jobs active still using them.
      const zoneInfo = zonesData.find((z) => z.name === selectedArea);
      if (zoneInfo.whiteCommands || zoneInfo.colorCommands) {
        if (selectedAction.type === "white") {
          selectedCommand = zoneInfo.whiteCommands[0]; // The schema needs to be changed to just one command since there are not multiple anymore.
        } else if (selectedAction.type === "color") {
          selectedCommand = zoneInfo.colorCommands[0];
        }
      } else {
        // If there are no commands, set it to null
        selectedCommand = null;
      }
    }

    const actionValue = selectedAction.value || selectedAction;

    const newSchedule = {
      name,
      startDate: startEpochTime,
      endDate: endEpochTime,
      selectedArea,
      selectedAction: actionValue,
      repeat,
      repeatDays,
      iotName,
      command: selectedCommand,
    };
    console.log("New Schedule:", newSchedule);

    try {
      // Use the createSchedule API function
      const response = await createSchedule(newSchedule);
      console.log("Schedule created successfully:", response.data);

      // Optionally, show a success message
      setSuccessMessage("Schedule saved successfully!");

      // Fetch updated schedules if function is provided
      if (typeof fetchSchedules === "function") {
        fetchSchedules();
      }
    } catch (error) {
      console.error("Error saving schedule:", error);
      setErrorMessage("Failed to save schedule. Please try again.");
    }

    resetForm();
  };

  const handleRepeatDayChange = (day) => {
    setRepeatDays((prevDays) =>
      prevDays.includes(day)
        ? prevDays.filter((d) => d !== day)
        : [...prevDays, day]
    );
  };

  // Returns an array of plain objects like:
  // [{ type: 'white', value: '128' }, { type: 'color', value: '255,0,0' }, ...]
  function getActions(zoneName, zonesData, sceneValues) {
    // Global/All Zones logic
    // If All Zones is chosen, return both scene and pattern arrays.
    if (zoneName === "All Zones") {
      return [
        /*...globalPatternValues.map(pattern => ({
          type: "pattern",
          value: pattern
        })),*/
        ...globalSceneValues.map((scene) => ({
          type: "scene",
          value: scene,
        })),
      ];
    }

    // Zones logic.
    // Find the zone
    const zone = zonesData.find((z) => z.name === zoneName);
    if (!zone) return [];

    let actions = [];

    // White
    if (zone.hasWhite && Array.isArray(zone.whiteValues)) {
      actions.push(
        ...zone.whiteValues.map((val) => ({
          type: "white",
          value: val, // e.g., '128', '255'
        }))
      );
    }

    // Color
    if (zone.hasColor && Array.isArray(zone.colorValues)) {
      let colorValues = zone.colorValues;

      // If there is a color wheel and colorValues is empty, use a template of 6 colors
      if (zone.hasColorWheel && colorValues.length === 0) {
        colorValues = [
          "255,0,0", // Red
          "0,255,0", // Green
          "0,0,255", // Blue
          "255,255,0", // Yellow
          "0,255,255", // Cyan
          "255,0,255", // Magenta
        ];
      }

      actions.push(
        ...colorValues.map((val) => ({
          type: "color",
          value: val, // e.g., '255,0,0'
        }))
      );
    }

    // Scene
    if (zone.hasScene && Array.isArray(zone.sceneValues)) {
      actions.push(
        ...zone.sceneValues.map((val) => ({
          type: "scene",
          value: val,
        }))
      );
    }

    // Show
    if (zone.hasShow && Array.isArray(zone.showValues)) {
      actions.push(
        ...zone.showValues.map((val) => ({
          type: "show",
          value: val,
        }))
      );
    }

    return actions;
  }

  function renderSelectedAction(actionObj) {
    if (!actionObj) {
      return "Select Action";
    }

    if (actionObj.type === "white") {
      return `${actionObj.value}% - White`;
    } else if (actionObj.type === "color") {
      return (
        <>
          <span
            style={{
              display: "flex",
              width: "92%",
              height: "20px",
              backgroundColor: `rgb(${actionObj.value})`,
              margin: "5px",
              padding: "5px",
            }}
          />
        </>
      );
    } else {
      // Scenes, Patterns, Shows, etc.
      return `${actionObj.value} - ${actionObj.type}`;
    }
  }

  // Handle zone selection
  function handleZoneSelect(zoneName) {
    setSelectedArea(zoneName);
    // Use the function above
    const actions = getActions(zoneName, zonesData, sceneValues);
    // Update state
    setAvailableActions(actions);
  }

  return (
    <Card className="scheduler-card">
      {/* Display Success Message */}
      {successMessage && (
        <Alert variant="success" className="mt-3">
          {successMessage}
        </Alert>
      )}

      {/* Display Error Message */}
      {errorMessage && (
        <Alert variant="danger" className="mt-3">
          {errorMessage}
        </Alert>
      )}

      <Form>
        {/* Name */}
        <Form.Group as={Row} controlId="formName" className="mb-3">
          <Form.Label column sm={2}>
            Name
          </Form.Label>
          <Col sm={4}>
            <Form.Control
              data-bs-theme="dark"
              type="text"
              className="input-field name-field"
              value={name}
              onChange={(e) => setName(e.target.value)}
              data-testid={`name-input`}
              required
            />
          </Col>
        </Form.Group>

        {/* Start Date & Time */}
        <Form.Group as={Row} controlId="formStartDateTime" className="mb-3">
          <Form.Label column sm={2}>
            Start Date & Time
          </Form.Label>
          <Col sm={2} style={{ minWidth: "190px" }}>
            <DatePicker
              selected={startDate}
              className="input-field"
              onChange={(date) => setStartDate(date)}
              dateFormat="MM/dd/yyyy"
              style={{ width: "190px" }}
              customInput={
                <Form.Control
                  type="text"
                  value={moment(startDate).format("MM/DD/YYYY")}
                  readOnly
                  required
                />
              }
            />
          </Col>
          <Col sm={2} style={{ minWidth: "190px" }}>
            <Form.Control
              type="time"
              value={startTime}
              className="input-field"
              onChange={(e) => setStartTime(e.target.value)}
              required
            />
          </Col>
        </Form.Group>

        {/* End Date & Time */}
        <Form.Group as={Row} controlId="formEndDateTime" className="mb-3">
          <Form.Label column sm={2}>
            End Date & Time
          </Form.Label>
          <Col sm={2} style={{ minWidth: "190px" }}>
            <DatePicker
              selected={endDate}
              className="input-field"
              onChange={(date) => setEndDate(date)}
              dateFormat="MM/dd/yyyy"
              style={{ width: "190px" }}
              customInput={
                <Form.Control
                  type="text"
                  value={moment(endDate).format("MM/DD/YYYY")}
                  readOnly
                  required
                />
              }
            />
          </Col>
          <Col sm={2} style={{ minWidth: "190px" }}>
            <Form.Control
              type="time"
              className="input-field"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              required
            />
          </Col>
        </Form.Group>

        {/* Select Zone */}
        <Form.Group as={Row} controlId="formArea" className="mb-3">
          <Form.Label column sm={2} style={{ minWidth: "190px" }}>
            Select Zone
          </Form.Label>
          <Col style={{ display: "flex", justifySelf: "start" }} sm={10}>
            <Dropdown data-bs-theme="dark" onSelect={(zoneName) => handleZoneSelect(zoneName)}>
              {/* Dropdown Toggle with unique test ID */}
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-area"
                style={{ width: "190px" }}
                data-testid="zone-dropdown-toggle"
              >
                {selectedArea || "Select Zone"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {zoneNames.map((zoneName, i) => (
                  <Dropdown.Item
                    key={i}
                    eventKey={zoneName}
                    data-testid={`zone-dropdown-item-${zoneName}`}
                  >
                    {zoneName}
                  </Dropdown.Item>
                ))}
                {systemInfo.sceneValues.length > 0 ? (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      eventKey="All Zones"
                      data-testid="zone-dropdown-item-All Zones"
                    >
                      All Zones
                    </Dropdown.Item>
                  </>
                ) : null}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Form.Group>

        {/* Select Action */}
        <Form.Group as={Row} controlId="formAction" className="mb-3">
          <Form.Label column sm={2}>
            Select Action
          </Form.Label>
          <Col sm={10}>
            <Dropdown data-bs-theme="dark">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-action"
                data-testid="action-dropdown-toggle"
                style={{ width: "190px" }}
              >
                {selectedAction
                  ? renderSelectedAction(selectedAction)
                  : "Select Action"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {availableActions.map((actionObj, index) => {
                  // White
                  if (actionObj.type === "white") {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => setSelectedAction(actionObj)}
                      >
                        {`${actionObj.value}% - White`}
                      </Dropdown.Item>
                    );
                  }

                  // Color
                  if (actionObj.type === "color") {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => setSelectedAction(actionObj)}
                      >
                        <span
                          style={{
                            display: "flex",
                            width: "92%",
                            height: "20px",
                            backgroundColor: `rgb(${actionObj.value})`,
                            margin: "5px",
                            padding: "5px",
                          }}
                        />
                      </Dropdown.Item>
                    );
                  }

                  // Scenes, Patterns, Shows, etc.
                  return (
                    <Dropdown.Item
                      key={index}
                      onClick={() => setSelectedAction(actionObj)}
                    >
                      {`${actionObj.value}`}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Form.Group>

        {/* Repeat Checkbox */}
        <Form.Group as={Row} controlId="formRepeat" className="mb-3">
          <Form.Label column sm={2}>
            Repeat
          </Form.Label>
          <Col sm={10} style={{ display: "flex", alignItems: "center" }}>
            <Form.Check
              type="checkbox"
              checked={repeat}
              onChange={(e) => setRepeat(e.target.checked)}
            />
          </Col>
        </Form.Group>

        {/* Repeat Days if needed */}
        {repeat && (
          <Form.Group as={Row} controlId="formRepeatDays" className="mb-3">
            <Form.Label column sm={2}>
              Repeat Days
            </Form.Label>
            <Col sm={10}>
              {daysOfWeek.map((day, i) => (
                <Form.Check
                  key={i}
                  type="checkbox"
                  style={{ display: "flex", alignItems: "flex-end" }}
                  label={day}
                  checked={repeatDays.includes(day)}
                  onChange={() => handleRepeatDayChange(day)}
                />
              ))}
            </Col>
          </Form.Group>
        )}

        {/* Save button */}
        <Row className="mb-3">
          <Col sm={2}></Col>
          <Col sm={4} style={{ paddingLeft: "-10px" }}>
            <Button
              variant="primary"
              onClick={handleSave}
              className="schedule-btn"
              style={{ marginLeft: "-10px !important" }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ScheduleForm;