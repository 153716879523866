import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { iotDeviceStatusLogic } from "../Utils/IotLogic";
import { Row, Col, Badge } from "react-bootstrap";
import SystemInfoContext from "../Context/SystemInfoContext";
import "../Css/fieldStatus.css";

function FieldLoader() {
  const [isDeviceOnline, setIsDeviceOnline] = useState("Establishing Connection...");
  const location = useLocation();
  const navigate = useNavigate();
  const systemInfo = location.state?.systemInfo;
  const projectName = systemInfo?.name || "Unknown Project";

  const isMounted = useRef(true);

  useEffect(() => {
    if (!systemInfo) {
      navigate("/"); // Redirect if no field information is available
      return;
    }

    isMounted.current = true;

    // Initialize IoT logic for device status
    const cleanupDeviceStatus = iotDeviceStatusLogic(
      location,
      (isOnline) => {
        setIsDeviceOnline(isOnline ? "Online" : "Offline");
      },
      isMounted
    )();

    return () => {
      isMounted.current = false;
      cleanupDeviceStatus(); // Cleanup on unmount
    };
  }, [location, systemInfo, navigate]);

  return (
    <main role="main">
      <Row className="align-items-center">
        <Col style={{ paddingLeft: "0px" }} xs="auto">
          <h4 className="job-title">{projectName}</h4>
        </Col>
        <Col xs="auto" style={{ paddingLeft: "0px" }}>
          <Badge
            pill
            style={{ margin: "0px" }}
            className={
              isDeviceOnline === "Online"
                ? "bg-success"
                : isDeviceOnline === "Offline"
                  ? "bg-danger"
                  : "bg-secondary"
            }
          >
            {isDeviceOnline}
          </Badge>
        </Col>
      </Row>

      {/* Provide the systemInfo to children via context */}
      <SystemInfoContext.Provider value={systemInfo}>
        <Outlet context={{ isDeviceOnline }} />
      </SystemInfoContext.Provider>
    </main>
  );
}

export default FieldLoader;