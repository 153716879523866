import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import "@aws-amplify/ui-react/styles.css";
import {
  inviteNewUserToMultipleSystems,
  fetchAllSysytems,
} from "../Utils/willUserUtils";
import "../Css/willAccess.css";

function InviteUser() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [systems, setSystems] = useState([]);

  // Fetch systems once on mount
  useEffect(() => {
    const fetchSystems = async () => {
      try {
        // fetchAllSysytemUser() should return an array of systems: [{ id, name, selected }, ...]
        const fetchedSystems = await fetchAllSysytems();
        setSystems(fetchedSystems);
      } catch (error) {
        console.error("Error fetching systems:", error);
      }
    };

    fetchSystems();
  }, []);

  // Toggle the "selected" property when a checkbox is changed
  const handleCheckboxChange = (id) => {
    setSystems((prevSystems) =>
      prevSystems.map((system) =>
        system.id === id
          ? { ...system, selected: !system.selected }
          : system
      )
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      // Filter out only systems the user selected
      const selectedSystems = systems.filter((sys) => sys.selected);

      // Example usage of the invite function (adjust parameters as needed)
      await inviteNewUserToMultipleSystems({
        email: email,
        systems: selectedSystems,
      });
      alert("User invited successfully!");
    } catch (error) {
      console.error("Error inviting user:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="will-card">
      <Card.Header as="h4">Invite User</Card.Header>
      <Card.Body>
        <Card.Title as="h6">Enter User Email:</Card.Title>
        <Form.Group
          className="mb-3"
          controlId="formBasicEmail"
          style={{ width: "100%" }}
        >
          <Form.Control
            type="email"
            placeholder="Enter email"
            data-bs-theme="dark"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Card.Title as="h6">Select Fields User Can Access:</Card.Title>
        <Row style={{ width: "100%" }}>
          {systems.map((system) => (
            <Col key={system.id} md={6}>
              <Form.Check
                type="checkbox"
                className="will-check"
                label={system.name}
                name={system.name}
                checked={!!system.selected}
                onChange={() => handleCheckboxChange(system.id)}
              />
            </Col>
          ))}
        </Row>

        <Button
          onClick={handleSubmit}
          className="my-button-style-will"
          disabled={isLoading}
        >
          {isLoading ? "Inviting..." : "Invite User"}
        </Button>
      </Card.Body>
    </Card>
  );
}

export default InviteUser;