import { pubsub } from "../../../shared/utils/Pubsub";

// IoT Logic for Device Status
export const iotDeviceStatusLogic = (
  location,
  handleDeviceStatus,
  isMountedRef
) => {
  const thingName = location.state?.systemInfo?.IOTThingName;
  if (!thingName) {
    console.error("Thing name is undefined. Cannot request device status.");
    return;
  }

  const GET_DEVICE_STATUS_RESPONSE_TOPIC = `$aws/things/${thingName}/statusResponse`;
  const GET_DEVICE_STATUS_REQUEST_TOPIC = `$aws/things/${thingName}/statusRequest`;
  const statusCommand = location.state?.systemInfo?.statusCommand || "STAT1AT"; //Sets statusCommand to STAT1AT if not provided in amplify.

  let responseTimeout = null;
  let attemptCounter = 0;

  const sendStatusRequest = () => {
    if (!isMountedRef.current) return;

    pubsub.publish({
      topics: GET_DEVICE_STATUS_REQUEST_TOPIC,
      message: { command: statusCommand },
    });

    responseTimeout = setTimeout(() => {
      if (!isMountedRef.current) return;

      attemptCounter += 1;
      console.log(`Attempt ${attemptCounter}: No response yet, retrying...`);
      if (attemptCounter >= 2) {
        console.log("No response after two attempts, marking device offline.");
        handleDeviceStatus(false); // Device offline after 2 failed attempts
      } else {
        sendStatusRequest(); // Retry sending the request
      }
    }, 3000); // Retry every 3 seconds
  };

  const subscribeToDeviceStatus = () => {
    const statusResponseSubscription = pubsub
      .subscribe({ topics: GET_DEVICE_STATUS_RESPONSE_TOPIC })
      .subscribe({
        next: (data) => {
          if (!isMountedRef.current) return;
          if (data.status === "ONLINE") {
            console.log("Device is online");
            handleDeviceStatus(true);
            clearTimeout(responseTimeout);
            attemptCounter = 0; // Reset attempts
          }
        },
        error: (error) => {
          console.error("Error receiving device status:", error);
          handleDeviceStatus(false);
        },
      });

    return statusResponseSubscription;
  };

  // Initialize device status logic
  const initDeviceStatusLogic = () => {
    const deviceStatusSubscription = subscribeToDeviceStatus();
    sendStatusRequest(); // Send initial status request

    return () => {
      deviceStatusSubscription.unsubscribe();
      if (responseTimeout) {
        clearTimeout(responseTimeout);
      }
    };
  };

  return initDeviceStatusLogic;
};

// IoT Logic for Shadow State
export const iotShadowStateLogic = (
  location,
  handleShadowUpdate,
  isMountedRef
) => {
  console.log(location)
  const thingName = location.state?.systemInfo?.IOTThingName;
  if (!thingName) {
    console.error("Thing name is undefined. Cannot request shadow state.");
    return;
  }

  const SHADOW_GET_ACCEPTED_TOPIC = `$aws/things/${thingName}/shadow/get/accepted`;
  const SHADOW_UPDATE_DOCUMENTS_TOPIC = `$aws/things/${thingName}/shadow/update/documents`;
  const GET_SHADOW_TOPIC = `$aws/things/${thingName}/shadow/get`;

  const subscribeToShadowUpdates = () => {
    console.log(
      "Subscribing to shadow update topic:",
      SHADOW_UPDATE_DOCUMENTS_TOPIC
    );

    const shadowUpdateSubscription = pubsub
      .subscribe({ topics: SHADOW_UPDATE_DOCUMENTS_TOPIC })
      .subscribe({
        next: (data) => {
          console.log("Received shadow state update (full data):", data);
          if (!isMountedRef.current) return;

          const newState = data.current?.state?.reported || {};
          console.log("New shadow state:", newState);
          handleShadowUpdate(newState);
        },
        error: (error) => {
          console.error("Error receiving shadow state:", error);
        },
      });

    return shadowUpdateSubscription;
  };

  const requestShadowState = () => {
    console.log("Requesting shadow state on topic:", GET_SHADOW_TOPIC);
    pubsub.publish({
      topics: GET_SHADOW_TOPIC,
      message: {}, // Request the shadow state
    });
  };

  // Initialize shadow state logic
  const initShadowStateLogic = () => {
    console.log("Initializing shadow state logic for thing:", thingName);
    const shadowStateSubscription = subscribeToShadowUpdates();
    requestShadowState(); // Request initial shadow state

    return () => {
      console.log("Cleaning up shadow state subscription");
      shadowStateSubscription.unsubscribe();
    };
  };

  return initShadowStateLogic;
};
