// CustomNavbar.js
import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import useSignOut from "../../../shared/hooks/useSignOut";
import willCloud from "shared/images/willcloud-logo-white.png";
import { FaUserCircle } from "react-icons/fa";
import useIsWill from "../hooks/useIsWill";
import SystemNavbar from "./SystemNavbar";
import "../Css/navbar.css";
import useIsSystemSelected from "../hooks/useIsSystemSelected";

/**
 * CustomNavbar component renders a responsive navigation bar with various links and options.
 * 
 * @component
 * @returns {JSX.Element} The rendered CustomNavbar component.
 * 
 * @example
 * return (
 *   <CustomNavbar />
 * )
 * 
 * @description
 * - The navigation bar includes a brand logo, a toggle button for smaller screens, and various navigation links.
 * - The `Selector` link is always displayed.
 * - If a system is selected, additional system-specific links are rendered.
 * - On smaller screens, additional links for managing WiLL users and signing out are displayed inline.
 * - A user options dropdown is available with options to manage WiLL users and sign out.
 * 
 * @hook
 * - `useState` to manage the expanded state of the navbar and screen size.
 * - `useEffect` to add and remove a resize event listener.
 * - `useLocation` to get the current location object.
 * - `useParams` to get URL parameters.
 * - `useSignOut` to handle the sign-out functionality.
 * - `useIsWill` to determine if the user is a WiLL user.
 * - `useIsSystemSelected` to check if a system is selected.
 */
const CustomNavbar = () => {
  const [expanded, setExpanded] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 991);
  const location = useLocation();
  const systemInfo = location.state?.systemInfo || {};
  const handleSignOut = useSignOut();
  const isWill = useIsWill();
  const isSystemSelected = useIsSystemSelected();

  useEffect(() => {
    const handleResize = () => setIsLargeScreen(window.innerWidth > 991);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNavClick = () => setExpanded(false);

  return (
    <Navbar
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      expand="lg"
      variant="dark"
      className="custom-navbar"
      style={{
        borderBottom: "2px solid #FFCF2E",
        height: "80px",
        margin: "0 auto",
      }}
    >
      <Container fluid>
        <Navbar.Brand as={Link} to="/" onClick={handleNavClick}>
          <img src={willCloud} alt="logo" className="willcloud-logo" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            {/* Always show Selector */}
            <Nav.Link
              as={Link}
              to="/"
              state={{ systemInfo }}
              className={`nav-link ${location.pathname === "/" ? "active" : ""
                }`}
              onClick={handleNavClick}
            >
              Selector
            </Nav.Link>

            {/* Only render system links if a system is selected */}
            {isSystemSelected && (
              <SystemNavbar
                system={systemInfo.name ? systemInfo.name.replace(/\s/g, '') : ''}
                systemInfo={systemInfo}
                handleNavClick={handleNavClick}
              />
            )}

            {/* Smaller screen items inline */}
            {!isLargeScreen && (
              <>
                {isWill && (
                  <Nav.Link
                    as={Link}
                    to="/Manage"
                    className={`${location.pathname === "/Manage" ? "active" : ""
                      }`}
                    onClick={handleNavClick}
                  >
                    WiLL User Manage
                  </Nav.Link>
                )}
                <Nav.Link
                  onClick={() => {
                    handleSignOut();
                    handleNavClick();
                  }}
                >
                  Sign Out
                </Nav.Link>
              </>
            )}
          </Nav>

          <Nav className="ms-5 ps-5">
            <Dropdown align="end" data-bs-theme="dark">
              <Dropdown.Toggle variant="link" id="dropdown-basic" aria-label="User Options">
                <FaUserCircle size={30} className="user-icon" />
              </Dropdown.Toggle>

              <Dropdown.Menu align="end">
                {isWill && (
                  <Dropdown.Item
                    as={Link}
                    to="/Manage"
                    className={`${location.pathname === "/Manage" ? "active" : ""
                      }`}
                    onClick={handleNavClick}
                  >
                    WiLL User Manage
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  onClick={() => {
                    handleSignOut();
                    handleNavClick();
                  }}
                >
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;