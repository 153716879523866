// src/Features/Scheduler/Utils/api/createSchedule.js

import axios from 'axios';

const API_BASE_URL = 'https://j3pp969u1j.execute-api.us-west-2.amazonaws.com/prod/';

/**
 * Creates a new schedule with the provided details.
 * @param {Object} schedule - The schedule object containing data to create.
 * @returns {Promise} - Axios post promise.
 */
const createSchedule = (schedule) => {
    return axios.post(`${API_BASE_URL}`, schedule);
};

export default createSchedule;