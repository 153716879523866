import React, { useEffect, useState, useRef, useContext } from "react";
import { useOutletContext } from "react-router-dom";
import LightingControlsView from "./LightingControlViews";
import useLightingControls from "../../Controls/Hooks/useLightingControls";
import { pubsub } from "../../../shared/utils/Pubsub"; // PubSub system for shadow state
import "bootstrap/dist/css/bootstrap.min.css";
import "../Css/field.css";
import SystemInfoContext from "../../FieldStatus/Context/SystemInfoContext";


function LightingControls() {
  const systemInfo = useContext(SystemInfoContext);


  // Get the device's online status from the outlet context (do not update here)
  const isDeviceOnline = useOutletContext();

  // State to track shadow state
  const [shadowState, setShadowState] = useState({});

  // Mounted reference for managing subscriptions
  const isMountedRef = useRef(true);

  // Topics for device status and shadow state
  const thingName = systemInfo?.IOTThingName;
  const SHADOW_GET_ACCEPTED_TOPIC = `$aws/things/${thingName}/shadow/get/accepted`;
  const SHADOW_UPDATE_DOCUMENTS_TOPIC = `$aws/things/${thingName}/shadow/update/documents`;
  const GET_SHADOW_TOPIC = `$aws/things/${thingName}/shadow/get`;

  // Clean up subscriptions
  const cleanupSubscriptions = (subscriptions) => {
    subscriptions.forEach((sub) => {
      try {
        sub.unsubscribe();
      } catch (e) {
        console.error("Error unsubscribing:", e);
      }
    });
    subscriptions.length = 0;
  };

  // Fetch shadow state only after the device is online
  useEffect(() => {
    if (!isDeviceOnline) return; // Wait for the device to be online

    let subscriptions = [];

    const subscribeToShadowState = () => {
      const shadowGetAcceptedSubscription = pubsub
        .subscribe({ topics: SHADOW_GET_ACCEPTED_TOPIC })
        .subscribe({
          next: (data) => {
            if (!isMountedRef.current) return;
            setShadowState(data.state?.reported || {});
          },
          error: (error) =>
            console.error("Error subscribing to shadow/get/accepted:", error),
        });

      const shadowUpdateDocumentsSubscription = pubsub
        .subscribe({ topics: SHADOW_UPDATE_DOCUMENTS_TOPIC })
        .subscribe({
          next: (data) => {
            if (!isMountedRef.current) return;
            setShadowState(data.current?.state?.desired || {});
          },
          error: (error) =>
            console.error(
              "Error subscribing to shadow/update/documents:",
              error
            ),
        });

      subscriptions.push(
        shadowGetAcceptedSubscription,
        shadowUpdateDocumentsSubscription
      );
    };

    const requestShadowState = () => {
      pubsub.publish({
        topics: GET_SHADOW_TOPIC,
        message: {}, // Request the shadow state
      });
    };

    // Subscribe to shadow state and request initial shadow state
    subscribeToShadowState();
    requestShadowState();

    // Cleanup on unmount
    return () => {
      cleanupSubscriptions(subscriptions);
    };
  }, [isDeviceOnline, thingName]); // Only fetch shadow state when device is online

  // Unconditionally call useLightingControls, even if shadowState is still empty
  const {
    clickedButton,
    selectedButtons,
    selectedColors,
    hasActiveScene,
    handleDimmerButtonClick,
    handleColorButtonClick,
    handleControlButtonClick,
    isButtonSelected,
  } = useLightingControls(shadowState, systemInfo);

  return (
    <LightingControlsView
      isDeviceOnline={isDeviceOnline}
      clickedButton={clickedButton}
      selectedButtons={selectedButtons}
      selectedColors={selectedColors}
      hasActiveScene={hasActiveScene}
      shadowState={shadowState}
      handleDimmerButtonClick={handleDimmerButtonClick}
      handleColorButtonClick={handleColorButtonClick}
      handleControlButtonClick={handleControlButtonClick}
      isButtonSelected={isButtonSelected}
    />
  );
}

export default LightingControls;
